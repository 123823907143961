import cn from 'classnames';
import React, { useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import Form from 'react-bootstrap/Form';

import { IFixedAddress } from '../../../types/api';
import CompactOrgAddressView from '../../CompactOrgAddressView/CompactOrgAddressView';

import styles from './AddressSelect.module.scss';

interface IAddressSelectProps {
  options: IFixedAddress[];
  value: string;
  onChange: (uid: string) => void;
  error?: string;
  onBlur?: () => void;
}

const AddressSelect: React.FC<IAddressSelectProps> = ({ options, value, onChange, onBlur, error }) => {
  const [search, setSearch] = useState<string>('');

  const filteredOptions = options.filter(
    (option) =>
      option.label?.toLowerCase().includes(search.toLowerCase()) ||
      option.address1.toLowerCase().includes(search.toLowerCase()),
  );

  const handleSelect = (uid: string) => {
    onChange(uid);
  };

  const toggle = React.useMemo(() => {
    const selectedOption = options.find((option) => option.uid === value);

    return selectedOption ? (
      <CompactOrgAddressView className={cn(styles.toggle, styles.trailingComa)} row={selectedOption} />
    ) : (
      <span className={styles.toggle}>Select Address</span>
    );
  }, [options, value]);

  return (
    <Dropdown>
      <p className={styles.label}>Select Delivery Address</p>
      <Dropdown.Toggle
        onBlur={onBlur}
        variant="success"
        id="dropdown-basic"
        className={cn(styles.container, { [styles.error]: !!error })}
      >
        {toggle}
        {error && <span className={styles.errorText}>{error}</span>}
      </Dropdown.Toggle>

      <Dropdown.Menu className={styles.menu}>
        <Form.Control
          type="text"
          placeholder="Search..."
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          className={styles.search}
        />
        {!!filteredOptions?.length && (
          <div className={styles.options}>
            {filteredOptions.map((option) => (
              <Dropdown.Item
                as={CompactOrgAddressView}
                row={option}
                className={cn(styles.item, { [styles.selected]: option.uid === value })}
                key={option.uid}
                onClick={() => handleSelect(option.uid || '')}
              />
            ))}
          </div>
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default AddressSelect;
