import cn from 'classnames';
import { useFormikContext } from 'formik';
import * as React from 'react';
import { Col } from 'react-bootstrap';

import ContactForm from '../../components/forms/ContactForm';

import { IContactInfo } from '../../types/api';

import styles from './ShippingForm.module.scss';

const ContactFormContainer = () => {
  const form = useFormikContext<Partial<IContactInfo>>();

  return (
    <div className={styles.formWrapper}>
      <ContactForm title="Enter contact information" form={form} className={cn(styles.form, styles.contact)} />
      <Col className={styles.requiredMessage}>
        <span className={styles.asterisk}>*</span> Required fields
      </Col>
    </div>
  );
};

export default ContactFormContainer;
