export enum ModeSwitcherStatesEnum {
  Expanded = 'expanded',
  Single = 'single',
}

export enum SendBoxModeEnum {
  Open = 'open',
  Closed = 'closed',
}

export enum AddressChoiceEnum {
  Form = 'form',
  Select = 'select',
  Nothing = '',
}
