import { FormikContextType, useFormikContext } from 'formik';
import * as React from 'react';
import { Col } from 'react-bootstrap';

import AddressChoiceTabs from '../../components/AddressChoiceTabs/AddressChoiceTabs';
import FixedAddress from '../../components/FixedAddress/FixedAddress';
import AddressForm from '../../components/forms/AddressForm';
import AddressSelect from '../../components/inputs/AddressSelect/AddressSelect';

import { IFixedAddress } from '../../types/api';
import {
  IAddressSuggestion,
  ICustomOrFixedAddressForm,
  IFixedAddressForm,
  IUserAddressForm,
} from '../../types/shipping';
import { AddressChoiceEnum } from '../../types/ui';
import { formatApiAddress } from '../../util/address';

import formStyles from '../../components/forms/Forms.module.scss';
import styles from './ShippingForm.module.scss';

interface IProps {
  addressBook?: IFixedAddress[];
  fixedAddress?: IFixedAddress[];
}

const AddressFormContainer: React.FC<IProps> = ({ addressBook, fixedAddress }) => {
  const form = useFormikContext<ICustomOrFixedAddressForm | IUserAddressForm | IFixedAddressForm>();

  const isFixedAddress = fixedAddress && fixedAddress?.length > 0;

  const handleSelect = React.useCallback(
    (address: IAddressSuggestion) => {
      const addressData = formatApiAddress(address);

      if (addressData) {
        form.setValues({ ...form.values, userAddress: { ...form.values.userAddress, ...addressData } });
      }
    },
    [form.setValues, form.values],
  );

  const renderAddressSelect = React.useCallback(
    (addresses?: IFixedAddress[]) => {
      if (!addresses) {
        return null;
      }

      const { errors, values, touched } = (form as FormikContextType<IFixedAddressForm>) || {};
      const isThereAnyChoice = addresses.length > 1;

      return isThereAnyChoice ? (
        <AddressSelect
          value={values.picked_address_id}
          onBlur={() => form.setFieldTouched('picked_address_id')}
          onChange={(id) => form.setFieldValue('picked_address_id', id)}
          options={addresses.sort((a, b) => {
            return (a.label ?? a.address1).toLowerCase().localeCompare((b.label ?? b.address1).toLowerCase());
          })}
          error={touched?.picked_address_id ? errors?.picked_address_id : undefined}
        />
      ) : (
        <FixedAddress address={addresses[0]} />
      );
    },
    [form.values, form.setFieldValue, form.errors, form.touched, isFixedAddress],
  );

  const shippingFormLayout = React.useMemo(() => {
    return (
      <>
        <AddressForm
          className={styles.form}
          form={form as FormikContextType<ICustomOrFixedAddressForm>}
          onReset={form.resetForm}
          onSelect={handleSelect}
        />
        <Col className={styles.requiredMessage} xs={12}>
          <span className={styles.asterisk}>*</span> Required fields
        </Col>
      </>
    );
  }, [form, handleSelect]);

  const multiChoiceLayout = React.useMemo(() => {
    const formik = form as FormikContextType<ICustomOrFixedAddressForm>;
    const activeTab = (form.values as { mode: AddressChoiceEnum }).mode;
    const changeTab = (mode: AddressChoiceEnum) => {
      form.setErrors({});
      form.setTouched({});
      form.setFieldValue('mode', mode);
    };

    return (
      <>
        <p className={formStyles.subtitle}>Choose how you want to receive the send.</p>
        <AddressChoiceTabs
          active={activeTab}
          onChange={changeTab}
          error={formik.touched.mode ? formik.errors.mode : undefined}
        />
        {activeTab === AddressChoiceEnum.Select && renderAddressSelect(addressBook)}
        {activeTab === AddressChoiceEnum.Form && shippingFormLayout}
      </>
    );
  }, [form, shippingFormLayout, renderAddressSelect]);

  return (
    <React.Fragment>
      <div className={styles.formWrapper}>
        <h4 className={formStyles.title}>Delivery Address</h4>
        {isFixedAddress ? (
          renderAddressSelect(fixedAddress)
        ) : (
          <>{addressBook && addressBook.length > 0 ? multiChoiceLayout : shippingFormLayout}</>
        )}
      </div>
    </React.Fragment>
  );
};

export default AddressFormContainer;
