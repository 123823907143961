export const RECEIVER_FIRST_NAME = 'receiver_first_name';
export const RECEIVER_LAST_NAME = 'receiver_last_name';
export const RECEIVER_COMPANY_NAME = 'receiver_company_name';
export const RECEIVER_PHONE = 'receiver_phone';
export const RECEIVER_ADDRESS1 = 'receiver_address1';
export const RECEIVER_ADDRESS2 = 'receiver_address2';
export const RECEIVER_STATE = 'receiver_state';
export const RECEIVER_ZIP = 'receiver_zip';
export const RECEIVER_COUNTRY = 'receiver_country';
export const RECEIVER_CITY = 'receiver_city';
export const RECEIVER_EMAIL = 'receiver_email';
export const RECEIVER_FIXED_ADDRESS = 'fixed_address';
export const RECEIVER_ADDRESS_BOOK = 'address_book';
export const ORDER_ID = 'order_id';
export const KEY = 'key';
export const ONE_LINK_ID = 'one_link_id';
export const ALLOWED_RECIPIENT_UID = 'allowed_recipient_uid';

export const PRE_CREATED_ENGAGEMENT_ID = 'pre_created_engagement_id';

export const UNITED_STATES = 'United States';

export enum FORMS_ENUM {
  Physical = 'Physical',
  Digital = 'Digital',
}

export const PASSWORD = 'password';
export const SEND_2FA_TO = 'send2FATo';
export const CODE = 'code';
