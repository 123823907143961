import { IAddressInfo, IContactInfo, IFixedAddress, TInitAddress } from '../types/api';
import { EMPTY_ADDRESS, EMPTY_CONTACT } from './address';

export const mapAddressInfo = (initAddress?: TInitAddress | null): IAddressInfo => {
  if (!initAddress) {
    return { ...EMPTY_ADDRESS };
  }

  const {
    receiver_phone,
    receiver_email,
    receiver_first_name,
    receiver_last_name,
    fixed_address,
    address_book,
    ...address
  } = initAddress;

  return {
    ...address,
  };
};

export const mapContactInfo = (initAddress?: TInitAddress | null): IContactInfo => {
  if (!initAddress) {
    return EMPTY_CONTACT;
  }

  const { receiver_email, receiver_phone, receiver_first_name, receiver_last_name } = initAddress;
  return {
    receiver_email,
    receiver_phone,
    receiver_first_name,
    receiver_last_name,
  };
};

export const isValidFixedAddress = (fixedAddress?: IFixedAddress): boolean => {
  if (!fixedAddress) {
    return false;
  }

  const { address2, state, ...address } = fixedAddress;

  return Object.values(address).every(Boolean);
};
