import { AddressChoiceEnum } from '../types/ui';

import { ReactComponent as EditIcon } from '../assets/edit.svg';
import { ReactComponent as BookIcon } from '../assets/icon-book.svg';

export const DEFAULT_SCROLL_DELAY_MS = 16;
export const DEFAULT_LOGO_URL = '/logo.svg';

export const FOOTER_DISCLAIMER =
  'Your address will not be shared or sold, and is only used once for delivering this package.';
export const CUSTOMIZE_STEP_BODY_TEXT =
  'Your sender provided a possibility to place any desired text in a predefined location on the item.';

export const SUCCESS_TITLE = 'Your form was submitted!';
export const SUCCESS_MESSAGE = 'Your package is being prepared and is on the way to you!';

export const MSKU_NOT_PICKED_ITEM_HINT = 'The image will appear after you choose customizable options';

export const CUSTOMIZABLE_ITEM_INDICATOR_HINT_TEXT = 'Customizable Item';
export const CUSTOMIZABLE_ITEM_DEFAULT_LABEL_TEXT = 'Input your text to customize the label';
export const CUSTOMIZABLE_ITEM_TEXT_MAX_LENGTH = 25;

export const ADDRESS_CHOICE_TABS = [
  {
    label: `${'Select'} from Address Book`, // It's not SQL, bro
    value: AddressChoiceEnum.Select,
    Icon: BookIcon,
  },
  {
    label: 'Enter Custom Address',
    value: AddressChoiceEnum.Form,
    Icon: EditIcon,
  },
];
