import cn from 'classnames';
import * as React from 'react';

import { IFixedAddress } from '../../types/api';

import styles from './CompactOrgAddressView.module.scss';

interface IProps {
  row: IFixedAddress;
  className?: string;
  onClick?: () => void;
}

export const CompactOrgAddressView: React.FC<IProps> = ({ className, row, onClick }) => {
  return (
    <div className={cn(styles.container, className)} onClick={onClick} role={!!onClick ? 'button' : ''}>
      {row.label && <div>{`${row.label}`}</div>}
      <div className={styles.address}>
        <span>{`${row.address1},\u00A0`}</span>
        {row.address2 && <span>{`${row.address2},\u00A0`}</span>}
        <span>{`${row.city},\u00A0`}</span>
        {row.state && <span>{`${row.state},\u00A0`}</span>}
        <span>{`${row.zip},\u00A0`}</span>
        <span>{row.country}</span>
      </div>
    </div>
  );
};

export default CompactOrgAddressView;
