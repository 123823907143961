import * as React from 'react';
import { Col } from 'react-bootstrap';

import { countryList } from '../../constants/countries';
import { IFixedAddress } from '../../types/api';

import styles from './FixedAddress.module.scss';

interface IProps {
  address: IFixedAddress | undefined;
}

const FixedAddress: React.FC<IProps> = ({ address }) => {
  const { address1, address2, city, state, country: countryCode, zip } = address || {};

  const countryName = React.useMemo(
    () => countryList.find(({ two_digit_code }) => two_digit_code === countryCode)?.name || countryCode,
    [countryCode],
  );

  return (
    <Col>
      <div className={styles.content}>
        <span>{address1}</span>
        <span>{`${address2 ? `${address2}, ` : ''}`}</span>
        <span>
          {city} {`${state ? ` ${state}, ` : ''}`}
          {countryName}
        </span>
        <span>{zip}</span>
      </div>
    </Col>
  );
};

export default FixedAddress;
