import cn from 'classnames';
import { FormikErrors, FormikProps, FormikTouched } from 'formik';
import * as React from 'react';
import { Col, Form, Row } from 'react-bootstrap';

import { USA } from '../../constants/countries';
import {
  RECEIVER_ADDRESS1,
  RECEIVER_ADDRESS2,
  RECEIVER_CITY,
  RECEIVER_COMPANY_NAME,
  RECEIVER_COUNTRY,
  RECEIVER_STATE,
  RECEIVER_ZIP,
} from '../../constants/shipping';
import { IAddressInfo } from '../../types/api';
import { ICustomOrFixedAddressForm } from '../../types/shipping';
import AutoCompleteInput from '../AutoCompleteInput/AutoCompleteInput';
import Input from '../Input/Input';
import CountrySelect from '../inputs/CountrySelect/CountrySelect';

import styles from './Forms.module.scss';

interface IProps {
  className?: string;
  form: FormikProps<ICustomOrFixedAddressForm>;
  onSelect: (i: any) => void;
  onReset?: () => void;
}

const AddressForm = ({ form, className, onSelect }: IProps) => {
  const values = form.values.userAddress;
  const errors =
    typeof form.errors.userAddress === 'object' ? form.errors.userAddress : ({} as FormikErrors<IAddressInfo>);
  const touched =
    typeof form.touched.userAddress === 'object' ? form.touched.userAddress : ({} as FormikTouched<IAddressInfo>);
  const prefix = 'userAddress.';

  const onChange = <T, _>(f: string, v: T, shouldValidate?: boolean) =>
    form.setFieldValue(`userAddress.${f}`, v, shouldValidate);

  const isUSA = React.useMemo(() => values?.[RECEIVER_COUNTRY] === USA.two_digit_code, [values?.[RECEIVER_COUNTRY]]);

  const handleCountrySelect = React.useCallback(
    (country: string) => {
      if (Object.keys(form.errors).length) {
        form.setTouched({});
        form.setErrors({});
      }

      onChange(RECEIVER_COUNTRY, country, false);
    },
    [onChange, form.errors, form.setTouched, form.setErrors, prefix],
  );

  const handleChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.value && Object.keys(form.errors).length) {
        form.setTouched({});
        form.setErrors({});
      }
      form.handleChange(e);
    },
    [form.handleChange, form.setTouched, form.errors],
  );

  return (
    <Form.Group className={cn(className, styles.shippingForm)}>
      <Row className={styles.row}>
        <Col className={styles.col}>
          <Form.Group as={Col} controlId="receiver_company_name">
            <Input
              label="Company Name"
              placeholder="Company Name"
              type="text"
              name={`${prefix}${RECEIVER_COMPANY_NAME}`}
              onChange={handleChange}
              onBlur={form.handleBlur}
              error={touched?.[RECEIVER_COMPANY_NAME] ? errors?.[RECEIVER_COMPANY_NAME] : undefined}
              value={values?.[RECEIVER_COMPANY_NAME] || ''}
            />
          </Form.Group>
        </Col>
        <Col className={styles.col}>
          <CountrySelect
            value={values?.[RECEIVER_COUNTRY]}
            onSelect={handleCountrySelect}
            error={touched?.[RECEIVER_COUNTRY] ? errors?.[RECEIVER_COUNTRY] : undefined}
          />
        </Col>
      </Row>
      <Row className={styles.row}>
        <Col className={styles.col}>
          {isUSA ? (
            <AutoCompleteInput
              label="Address Line 1"
              isRequired
              name={`${prefix}${RECEIVER_ADDRESS1}`}
              onChange={handleChange}
              onBlur={form.handleBlur}
              error={touched?.[RECEIVER_ADDRESS1] ? errors?.[RECEIVER_ADDRESS1] : undefined}
              value={values?.[RECEIVER_ADDRESS1]}
              onSelect={onSelect}
              autoComplete="nope!"
            />
          ) : (
            <Input
              isRequired
              label="Address Line 1"
              placeholder="Address Line 1"
              type="text"
              name={`${prefix}${RECEIVER_ADDRESS1}`}
              onChange={handleChange}
              onBlur={form.handleBlur}
              error={touched?.[RECEIVER_ADDRESS1] ? errors?.[RECEIVER_ADDRESS1] : undefined}
              value={values?.[RECEIVER_ADDRESS1] || ''}
            />
          )}
        </Col>
      </Row>

      <Row className={styles.row}>
        <Col className={styles.col}>
          <Input
            label="Address Line 2"
            placeholder="Address Line 2"
            type="text"
            name={`${prefix}${RECEIVER_ADDRESS2}`}
            onChange={handleChange}
            onBlur={form.handleBlur}
            value={values?.[RECEIVER_ADDRESS2] || ''}
          />
        </Col>
        <Col className={styles.col}>
          <Input
            isRequired
            label="City"
            placeholder="City"
            type="text"
            name={`${prefix}${RECEIVER_CITY}`}
            onChange={handleChange}
            onBlur={form.handleBlur}
            error={touched?.[RECEIVER_CITY] ? errors?.[RECEIVER_CITY] : undefined}
            value={values?.[RECEIVER_CITY] || ''}
          />
        </Col>
      </Row>

      <Row className={styles.row}>
        <Col className={styles.col}>
          <Input
            isRequired={isUSA}
            label="State / Region"
            placeholder="State / Region"
            type="text"
            name={`${prefix}${RECEIVER_STATE}`}
            onChange={handleChange}
            onBlur={form.handleBlur}
            error={touched?.[RECEIVER_STATE] ? errors?.[RECEIVER_STATE] : undefined}
            value={values?.[RECEIVER_STATE] || ''}
          />
        </Col>

        <Col className={styles.col}>
          <Input
            isRequired
            label="ZIP Code"
            placeholder="ZIP Code"
            type="text"
            name={`${prefix}${RECEIVER_ZIP}`}
            onChange={handleChange}
            onBlur={form.handleBlur}
            error={touched?.[RECEIVER_ZIP] ? errors?.[RECEIVER_ZIP] : undefined}
            value={values?.[RECEIVER_ZIP] || ''}
          />
        </Col>
      </Row>
    </Form.Group>
  );
};

export default AddressForm;
