import cn from 'classnames';
import * as React from 'react';
import { ADDRESS_CHOICE_TABS } from '../../constants/ui';

import { AddressChoiceEnum } from '../../types/ui';

import styles from './AddressChoiceTabs.module.scss';

interface IProps {
  className?: string;
  active: AddressChoiceEnum;
  onChange: (active: AddressChoiceEnum) => void;
  error?: string;
}

const AddressChoiceTabs = ({ className, active, onChange, error }: IProps) => {
  return (
    <div className={cn(styles.tabs, className, { [styles.error]: !!error })}>
      {ADDRESS_CHOICE_TABS.map(({ value, label, Icon }) => (
        <div
          role="button"
          onClick={() => onChange(value)}
          key={value}
          className={cn(styles.tab, { [styles.active]: active === value })}
        >
          <Icon className={styles.icon} />
          {label}
        </div>
      ))}
      {error && <span className={styles.errorMessage}>{error}</span>}
    </div>
  );
};

export default AddressChoiceTabs;
