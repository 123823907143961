import { createSelector } from 'reselect';

import { routes } from '../../constants/routes';
import { IAppState } from '../../types/store';
import { getColor } from '../../util/helpers';

import styleVariables from '../../_variables.scss';

export const selectRootState = (state: IAppState) => state.init;

export const selectCampaigns = createSelector(selectRootState, (root) => root.campaigns);

export const selectErrorCode = createSelector(selectRootState, (root) => root.error?.error_code);

export const selectItems = createSelector(selectRootState, (root) => root.items);

export const selectIsDigital = createSelector(selectRootState, (root) => root.isDigital);

export const selectFixedAddress = createSelector(selectRootState, (root) => root.address?.fixed_address);

export const selectAddressBook = createSelector(selectRootState, (root) => root.address?.address_book);

export const selectIsLoading = createSelector(selectRootState, (root) => root.isLoading);

export const selectPassword = createSelector(selectRootState, (root) => root.password);

export const select2FATo = createSelector(selectRootState, (root) => root.send2FATo);

export const selectCode = createSelector(selectRootState, (root) => root.code);

export const selectInitialAddress = createSelector(selectRootState, (root) => root.address || undefined);

export const selectOrgData = createSelector(selectRootState, (root) => root.org);

export const selectSenderData = createSelector(selectRootState, (root) => root.sender);

export const selectWhiteLabeling = createSelector(selectRootState, (root) => root.whiteLabeling);

export const selectIsFormSubmitted = createSelector(selectRootState, (root) => root.isFormSubmitted);

export const selectIsPYGFlow = createSelector(selectCampaigns, (campaigns) => campaigns?.length);

export const selectUserId = createSelector(selectSenderData, (sender) => sender?.sender_uid);

export const selectBaseColor = createSelector(
  selectOrgData,
  selectWhiteLabeling,
  // Below is workaround to avoid the issue with Reference Error https://github.com/facebook/create-react-app/issues/9936
  // It's impossible to import `selectPickedCampaign for some reason`
  (state: IAppState) => state,
  (orgInfo, whiteLabeling, { init: { campaigns }, form: { pickedCampaignId }, router: { location } }) => {
    const pickedCampaign = campaigns?.find(({ id }) => id === pickedCampaignId);

    // PYG step should use org white-labeling because there's no selected campaign
    if (pickedCampaign && pickedCampaign.ds_header_color && location.pathname !== routes.pyg) {
      return getColor(pickedCampaign.ds_header_color);
    }

    if (whiteLabeling && whiteLabeling.ds_header_color) {
      return getColor(whiteLabeling.ds_header_color);
    }

    return getColor(orgInfo?.header_color, styleVariables.defaultBaseColor);
  },
);

export const selectAccentColor = createSelector(
  selectOrgData,
  selectWhiteLabeling,
  // Below is workaround to avoid the issue with Reference Error https://github.com/facebook/create-react-app/issues/9936
  // It's impossible to import `selectPickedCampaign for some reason`
  (state: IAppState) => state,
  (orgInfo, whiteLabeling, { init: { campaigns }, form: { pickedCampaignId }, router: { location } }) => {
    const pickedCampaign = campaigns?.find(({ id }) => id === pickedCampaignId);

    // PYG step should use org white-labeling because there's no selected campaign
    if (pickedCampaign && pickedCampaign.ds_button_color && location.pathname !== routes.pyg) {
      return getColor(pickedCampaign.ds_button_color);
    }

    if (whiteLabeling && whiteLabeling.ds_button_color) {
      return getColor(whiteLabeling.ds_button_color);
    }

    return getColor(orgInfo?.button_color, styleVariables.defaultAccentColor);
  },
);

export const selectResultScreenColor = createSelector(selectBaseColor, (baseColor) => {
  if (baseColor?.toLowerCase() === styleVariables.defaultBaseColor?.toLowerCase()) {
    return styleVariables.defaultResultScreenColor;
  }

  return baseColor;
});
